<template>
  <div class="m1">
    <div class="m1-h">
      <h2>财务数据</h2>
      <div class="m1-texts">
        财务总收 {{data.total_amount}}
        <span style="margin-left: 40px">时间内财务收入 {{data.date_amount}}</span>
      </div>
      <div class="m1-btns">
        <el-input v-model="pointForm.user_key_id" style="width:169px;" placeholder="请输入用户ID"></el-input>
        <el-button type="primary" @click="search()" style="margin:0 34px;">查询</el-button>
        <el-date-picker
          v-model="dates"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </div>
    </div>
    <el-table
      border
      :data="pointListData.data"
      style="width: 100%">
      <el-table-column
        prop="user_key_id"
        label="用户ID">
      </el-table-column>
      <el-table-column
        prop="create_time"
        label="充值时间">
      </el-table-column>
      <el-table-column
        prop="amount"
        label="充值金额">
      </el-table-column>
      
    </el-table>
    <div class="m1-r1">
      <div>共<span class="m1-text1">{{pointListData.total}}</span>条记录</div>
      <div>
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="getPointList()"
          :current-page.sync="pointForm.page"
          :total="pointListData.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  pointStat, pointList
} from '@/api/finance';
import moment from 'moment'
export default {
  name: 'index',
  data() {
    return {
      form: {
        user_id: this.$route.query.id,
        type: '',
        begin_date: '',
        end_date: '',
      },
      data: [],
      dates: [],
      pointForm: {
        user_id: this.$route.query.id,
        user_key_id: '',
        type: '',
        begin_date: '',
        end_date: '',
        page: 1,
        page_size: 10
      },
      pointListData: {},
    }
  },
  computed: {
  },
  created() {
    
  },
  mounted(){
    this.search()
  },
  methods: {
    getPointStat () {
      this.form.begin_date = this.dates && this.dates.length > 0 ? moment(this.dates[0]).format('YYYY-MM-DD') : '';
      this.form.end_date = this.dates && this.dates.length > 0 ? moment(this.dates[1]).format('YYYY-MM-DD') : '';
      pointStat(this.form).then(res =>{
        this.data = res.data.data
      })
    },
    getPointList () {
      this.pointForm.begin_date = this.dates && this.dates.length > 0 ? moment(this.dates[0]).format('YYYY-MM-DD') : '';
      this.pointForm.end_date = this.dates && this.dates.length > 0 ? moment(this.dates[1]).format('YYYY-MM-DD') : '';
      pointList(this.pointForm).then(res =>{
        this.pointListData = res.data.data
      })
    },
    search () {
      this.getPointStat()
      this.getPointList()
    }
  },
}
</script>
<style scoped lang="scss">
.m1-h{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.m1-r1{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.m1-text1{
  color: #409EFF;
}
</style>
